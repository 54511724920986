import { Component } from '@angular/core';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { Router } from "@angular/router";
import {GlobalConstants} from "src/app/global-constants"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  GC: any = GlobalConstants
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(private router: Router, private idle: Idle, private keepalive: Keepalive) {
    /***/
    let domainUrl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
    if(domainUrl?.includes('ypmavanti')){
      this.GC.adminStr1 = this.GC.adminStr2
      this.GC.adminar = this.GC.admin2ar
    }
    /***/
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle((60*60*25));
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(60*1);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logMeOut();
      this.reset();
    });
    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.info("timout going to happen")

    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();

  }
  logMeOut(){
    localStorage.removeItem('_token');
    this.router.navigate(['/login']);
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
