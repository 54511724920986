import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        mod => mod.DashboardModule,
      ),
  },
  // Auth
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(
        mod => mod.AuthModule
      )
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ]
})
export class AppRoutingModule { }
