interface GC {
  adminStr1
  adminStr2
  internals
}
class theGlobalConstants implements GC{ //GlobalConstants
  public adminStr1: String = "YPM Admin";
  public adminStr2: String = "YPM Avanti Admin";
  public adminar = ['YPM Admin'];
  public admin2ar = ['YPM Avanti', 'YPM Avanti Admin'];
  public internals: any = ["YPM Admin","YPM Sales Managers","YPM Sales Staff", "Avanti & YPM","YPM Avanti", "YPM Avanti Admin"];
  // public PageSize: number[] = [10, 50, 100, 200, 500];
  // public AllowFiltering: boolean = true;
  public active_precint_id = null;
  public active_precint_name = '';
}
const GlobalConstants = new theGlobalConstants();
export {GlobalConstants};