import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment'
import { Router } from "@angular/router";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  info = null;
  public waslogged:boolean = true
  constructor(private http: HttpClient, private router: Router) { }

  login(data){
    return this.http.post<any>(environment.api +'-user', data).toPromise().then(res => {
      localStorage.setItem('_token', res.UserId);
      localStorage.setItem('user', JSON.stringify(res.User));
      this.waslogged = res.waslogged;

      // this.user = JSON.parse(localStorage.getItem('user'));

      if(res.User.customertypecode_formatted === 'Developer'){
        this.router.navigate(['/admin/my-projects']);
      }else {
      if(!this.waslogged){
        this.router.navigate(['/admin/settings']);
      } else {
        this.router.navigate(['/admin']);
      }
    }
    },error => {
      error.error.error;
    })
  }

  reset(data){
    return this.http.post<any>(environment.api +'-user/forgotpass', data).toPromise().then(res => {
      if(res.UserId)
      {
        localStorage.setItem('_token', res.UserId);
        localStorage.setItem('user', JSON.stringify(res.User));
        this.router.navigate(['/admin']);
      }
      return res;
    });
  }
}
