import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

Injectable()
export class Globals{
  private data = new BehaviorSubject({projectname: null, link: null, id: null, precint_name: '', precint_id: null});
  currentData = this.data.asObservable();
    public breadCrubs:any = [];
    public projectname = 'ttt';
    public link = '';
    changeData(data:any) {
      this.data.next(data)
    }
}
